
import {
  defineComponent,
  reactive,
  ref,
  toRefs,
  onMounted,
  nextTick,
} from 'vue'
import { digitUppercase, enumToOption } from '@/utils/index'
import { detailApi, paymentEditAPI } from './api'
import { feeType, state, payChannel } from './config'
import { ElMessage } from 'element-plus'
import { required, numberAndDecimalPint } from '@/utils/rules'
import { detailOfPayment } from '../../enterprise-management/enterprise/api'
import { toResult } from '@/utils/toResult'
import moment from 'moment'

export default defineComponent({
  props: {
    visible: Boolean,
    rowData: Object,
    isTyp: String,
  },
  setup(props, { emit }) {
    const form: any = { paymentType: 1, owingMoney: 0 }

    const data = reactive({
      dialogVisible: props.visible,
      form,
      fillDeductionRecordList: ref<any>({}),
      formRef: null,
      payChannelOption: enumToOption(payChannel), //  缴费渠道

      // 单车基础费每天
      basicCarDailyAmount: 0,
      // 单车基础费每年
      basicCarYearAmount: 0,
      // 单车收费费每天
      chargeCarDailyAmount: 0,
      // 单车收费费每年
      chargeCarYearAmount: 0,
    })

    const checkCarGivingMoney = (rule: any, value: any, callback: any) => {
      // if (value > 100000) {
      //   callback(new Error('优惠金额范围0-100000'))
      // } else
      if (Number(value) > Number(data.form?.payableAmount || 0)) {
        callback(new Error('优惠金额不能大于应缴总金额'))
      } else {
        callback()
      }
    }
    const checkCarPayAmount = (rule: any, value: any, callback: any) => {
      if (value > 500000) {
        callback(new Error('实缴金额范围0-50万'))
      } else {
        callback()
      }
    }

    const checkServiceMealPayAmount = (
      rule: any,
      value: any,
      callback: any
    ) => {
      if (value > 500000) {
        callback(new Error('实缴金额范围0-50万'))
      } else {
        callback()
      }
    }

    const rules = ref<any>({
      initialType: [required],
      feeType: [required],
      state: [required],
      paymentType: [required],
      payChannel: [required],
      // payRemark: [required],
      internalRemark: [required],
      thirdTradeNo: [required],
      payTime: [required],
      createTime: [required],
      basicCarNum: [
        required,
        {
          pattern: /^(([0-9][0-9]*))$/,
          message: '只支持输入0-90000',
          trigger: 'blur',
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (Number(value) > 90000) {
              callback(new Error('不能大于90000'))
            } else {
              callback()
            }
          },
          trigger: 'blur',
        },
      ],
      actualMoney: [
        required,
        numberAndDecimalPint,
        { validator: checkServiceMealPayAmount, trigger: ['blur', 'change'] },
      ],
      carPayAmount: [
        required,
        numberAndDecimalPint,
        { validator: checkCarPayAmount, trigger: ['blur', 'change'] },
      ],
      givingMoney: [
        numberAndDecimalPint,
        {
          validator: (rule, value, callback) => {
            if (!value) {
              return callback(new Error('请输入数字'))
            }
            if (value < 0 || value > 99999) {
              callback(new Error('输入的数字必须在0到99999之间'))
            } else {
              callback()
            }
          },
          trigger: 'blur',
        },
        { validator: checkCarGivingMoney, trigger: ['blur', 'change'] },
      ],
      payableAmount: [required, numberAndDecimalPint],
      paymentPeriod: [
        { required: true, message: '必填', trigger: ['blur', 'change'] },
        {
          pattern: /^([1-9]|10)$/,
          message: '只支持输入1-10',
          trigger: ['blur', 'change'],
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (value > 10) {
              callback(new Error('不能大于10'))
            } else {
              callback()
            }
          },
          trigger: ['blur', 'change'],
        },
      ],
      chargeCarNum: [
        required,
        {
          pattern: /^(([0-9][0-9]*))$/,
          message: '只支持输入0-90000',
          trigger: 'blur',
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (Number(value) > 90000) {
              callback(new Error('不能大于90000'))
            } else {
              callback()
            }
          },
          trigger: 'blur',
        },
      ],
    })

    // 枚举
    const Enum = {
      feeType,
      state,
      payChannel,
    }

    // 计算逻辑
    const totalChange = (val) => {
      console.log(val)

      if (data.form.feeType === 1) {
        // 应缴总金额
        let p1 = 0
        let p2 = 0
        // 年
        // 应缴总金额 = （基础车辆数 * 单车基础年费 * 年限） + （收费车辆数 * 单车收费年费*年限 ）应缴总金额，不可修改
        if (data.form.paymentType === 1) {
          p1 =
            Number(data.form.basicCarNum || 0) *
            Number(data.basicCarYearAmount || 0) *
            Number(data.form.paymentPeriod || 0)
          p2 =
            Number(data.form.chargeCarNum || 0) *
            Number(data.chargeCarYearAmount || 0) *
            Number(data.form.paymentPeriod || 0)
        }
        // 天
        if (data.form.paymentType === 2) {
          p1 =
            Number(data.form.basicCarNum || 0) *
            Number(data.basicCarDailyAmount || 0) *
            Number(data.form.paymentPeriod || 0)
          p2 =
            Number(data.form.chargeCarNum || 0) *
            Number(data.chargeCarDailyAmount || 0) *
            Number(data.form.paymentPeriod || 0)
        }
        console.log(p1, p2)
        data.form.payableAmount = p1 + p2 || 0

        data.form.payableAmount = Number(data.form.payableAmount || 0).toFixed(
          2
        )
        // 实缴金额 = 应缴总金额 - 优惠金额 ，不可为空，仅数字，默认等于应缴总金额。
        data.form.carPayAmount =
          Number(data.form.payableAmount || 0) -
            Number(data.form.givingMoney || 0) || 0
        data.form.carPayAmount = (Number(data.form.carPayAmount) || 0).toFixed(
          2
        )

        // 账户实到金额：  实缴金额 - 欠费金额 （当前不会产生欠费）
        data.form.actualMoney = (
          Number(data.form.carPayAmount || 0) -
          Number(data.form.owingMoney || 0)
        ).toFixed(2)
      }
      if (data.form.feeType === 3) {
        // 账户实到金额：  实缴金额 - 欠费金额 （当前不会产生欠费）
        data.form.actualMoney = (
          Number(data.form.payableAmount || 0) -
          Number(data.form.givingMoney || 0)
        ).toFixed(2)
        data.form.carPayAmount =
          Number(data.form.payableAmount || 0) -
          Number(data.form.givingMoney || 0)
      }
    }

    const actualMoneyChange = () => {
      // 账户实到金额：  实缴金额 - 欠费金额 （当前不会产生欠费）
      data.form.actualMoney = (
        Number(data.form.carPayAmount || 0) - Number(data.form.owingMoney || 0)
      ).toFixed(2)
    }

    const handleClose = () => {
      emit('update:visible', false)
    }

    const handleConfirm = async () => {
      const p: any = {
        id: props.rowData.id,
        uuid: data.form.uuid,
        companyUuid: data.form.companyUuid,
        carPayAmount: data.form.carPayAmount,
        carGivingMoney: Number(data.form.givingMoney),
        payChannel: data.form.payChannel,
        payTime: data.form.payTime,
        internalRemark: data.form.internalRemark,
        payRemark: data.form.payRemark,
        initialType: data.form.initialType,
        carBaseNum: data.form.basicCarNum,
        carChargeNum: data.form.chargeCarNum,
        paymentType: data.form.paymentType,
        paymentPeriod: data.form.paymentPeriod,
        payableAmount: data.form.payableAmount,
        state: data.form.state,
        feeType: data.form.feeType,
      }
      if (data.form.feeType === 2) {
        p.serviceMealPayAmount = p.carPayAmount
        delete p.carPayAmount
      }

      if (p.payTime) p.payTime = moment(p.payTime).format('YYYY-MM-DD HH:mm:ss')
      const [err, res] = await toResult(paymentEditAPI(p))
      if (err) return false
      ElMessage.success('成功')
      handleClose()
      emit('getListFn')
      console.log(res)
    }

    const changePayChannel = (val) => {
      console.log(val)
    }

    // 保存提交
    const handleSubmit = () => {
      console.log(data.form)
      data.formRef.validate((valid) => {
        if (data.form.feeType !== 3) {
          if (!valid) {
            return false
          }
        }
        if (data.form.feeType === 3) {
          data.form.basicCarNum = 0
          data.form.chargeCarNum = 0
          if (data.form.givingMoney > data.form.payableAmount) {
            return false
          }
        }
        if (Number(data.form.carBaseNum) + Number(data.form.carBaseNum) <= 0) {
          ElMessage.error('收费辆数和车辆数两个相加必须大于0')
          return false
        }
        handleConfirm()
      })
    }

    const changePaymentType = (val) => {
      totalChange(val)
      data.formRef.validate((valid) => {
        if (!valid) {
          return false
        }
      })
      data.form.paymentPeriod = ''
      if (val === 1) {
        rules.value.paymentPeriod = [
          { required: true, message: '必填', trigger: ['blur', 'change'] },
          {
            pattern: /^([1-9]|10)$/,
            message: '只支持输入1-10',
            trigger: ['blur', 'change'],
          },
          {
            validator: (rule: any, value: any, callback: any) => {
              if (Number(value) > 10) {
                callback(new Error('不能大于10'))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change'],
          },
        ]
      } else {
        rules.value.paymentPeriod = [
          { required: true, message: '必填', trigger: ['blur', 'change'] },
          {
            pattern: /^(([1-9][0-9]*))$/,
            message: '只支持输入1-3650',
            trigger: ['blur', 'change'],
          },
          {
            validator: (rule: any, value: any, callback: any) => {
              console.log(value)
              if (!Number.isInteger(value) && Number(value) > 3650) {
                callback(new Error('只支持输入1-3650'))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change'],
          },
        ]
      }
    }
    onMounted(() => {
      detailOfPayment({ companyUuid: props.rowData.companyUuid }).then(
        (res) => {
          nextTick(() => {
            data.form.presetRoleName = res.data.basicAnnualFee
            data.form.owingMoney = res.data.owingMoney
            data.basicCarDailyAmount = res.data.basicCarDailyAmount || 0
            data.basicCarYearAmount = res.data.basicCarYearAmount || 0
            data.chargeCarDailyAmount = res.data.chargeCarDailyAmount || 0
            data.chargeCarYearAmount = res.data.chargeCarYearAmount || 0
            console.log(data.form)
          })
        }
      )
      console.log(data.form)
      detailApi({ id: props.rowData.id }).then((res) => {
        data.form = {
          ...data.form,
          ...res.data,
        }
        data.form.carPayAmount = res.data.payAmount
        nextTick(() => {
          data.form.actualMoney = res.data.actualMoney
        })

        if (data.form.paymentType === 1) {
          rules.value.paymentPeriod = [
            { required: true, message: '必填', trigger: ['blur', 'change'] },
            {
              pattern: /^([1-9]|10)$/,
              message: '只支持输入1-10',
              trigger: ['blur', 'change'],
            },
            {
              validator: (rule: any, value: any, callback: any) => {
                if (value > 10) {
                  callback(new Error('不能大于10'))
                } else {
                  callback()
                }
              },
              trigger: ['blur', 'change'],
            },
          ]
        } else if (data.form.paymentType === 2) {
          rules.value.paymentPeriod = [
            { required: true, message: '必填', trigger: ['blur', 'change'] },
            {
              pattern: /^(([1-9][0-9]*))$/,
              message: '只支持输入1-3650',
              trigger: ['blur', 'change'],
            },
            {
              validator: (rule: any, value: any, callback: any) => {
                console.log(value)
                if (!Number.isInteger(value) && Number(value) > 3650) {
                  callback(new Error('只支持输入1-3650'))
                } else {
                  callback()
                }
              },
              trigger: ['blur', 'change'],
            },
          ]
        }
      })

      // changePaymentType(data.form.paymentType)
    })

    return {
      ...toRefs(data),
      ...Enum,
      rules,
      handleClose,
      digitUppercase,
      handleSubmit,
      changePayChannel,
      changePaymentType,
      totalChange,
      actualMoneyChange,
    }
  },
})
