
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
export default defineComponent({
  name: 'CButtonGroup',
  setup() {
    const buttonList = ref<HTMLElement | any>(null)
    const firstButtonRef = ref<HTMLElement | any>(null)

    const data = reactive({
      isMore: false,
      firstButton: undefined
    })

    const init = () => {
      // 获取当前权限可以显示的按钮列表
      const list = Array.from(buttonList.value.children).filter(
        (el: HTMLElement) => el.style.display !== 'none'
      )
      // 权限可显示的按钮数量
      const len = list.length

      if (len > 0) {
        // 第一个权限可见的按钮
        data.firstButton = list[0]
        data.firstButton.classList.add('el-button--mini')
        firstButtonRef.value.appendChild(data.firstButton)
      }

      // 超过1个就展示下拉菜单
      if (len > 1) data.isMore = true
    }

    onMounted(() => {
      init()
    })
    return {
      ...toRefs(data),
      buttonList,
      firstButtonRef
    }
  }
})
