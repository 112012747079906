/*
 * @Description: 配合权限展示table操作按钮组
 * @Author: yaoxiaoxiao
 * @RearEnd:
 * @Date: 2021-10-20 17:28:17
 * @LastEditors: yaoxiaoxiao
 * @LastEditTime: 2021-10-21 14:37:11
 */
import CButtonGroup from './index.vue'
export default CButtonGroup
