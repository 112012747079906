/*
 * @Description:
 * @Author: yyx
 * @@后台人员: xxx
 * @Date: 2022-05-19 11:42:20
 * @LastEditors: yyx
 * @LastEditTime: 2022-05-25 13:42:38
 */
import service from '@/utils/request'

// 列表
export const getPageOfEnterprise = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterprise/payment/page',
    data: params
  })

// 查询汇总
export const sumApi = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterprise/payment/sum',
    data: params
  })

// 详情
export const detailApi = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterprise/payment/detail',
    data: params
  })

// 企业详情-缴费记录-删除
export const paymentDelAPI = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterprise/payment/del',
    data: params
  })
// 企业详情-缴费记录-编辑
export const paymentEditAPI = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterprise/payment/edit',
    data: params
  })
