/*
 * @Description:
 * @Author: rongcheng
 * @@后台人员: xxx
 * @Date: 2021-04-07 15:28:11
 * @LastEditors: panyufeng
 * @LastEditTime: 2024-08-30 14:21:24
 */
import service from '@/utils/request'

// 列表
export const getPageOfEnterprise = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/enterprise/getPageOfEnterprise',
    data: params
  })

// 添加
export const setAddEnterprise = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/enterprise/addEnterprise',
    data: params
  })

// 编辑
export const setEditEnterprise = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/enterprise/editEnterprise',
    data: params
  })
// 详情
export const getDetailOfEnterprise = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/enterprise/detailOfEnterprise',
    data: params
  })

// 预置角色
export const getPresetRole = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/enterprise/addEnterprisePresetRole',
    data: params
  })
// 获取预置角色下拉框
export const getRoleList = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/preset/role/list',
    data: params
  })

// 缴费操作
export const paymentAdd = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterprise/payment/add',
    data: params
  })

// 缴费操作详情
export const detailOfPayment = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterprise/payment/detailOfPayment',
    data: params
  })

// 企业详情续签
export const renewApi = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/serve/record/renew',
    data: params
  })

// 企业套餐列表获取
export const getComboListAPI = (params: any) =>
  service.request({
    method: 'post',
    url: '/serve/combo/page',
    data: params
  })

// 配置套餐-确定接口
export const recordAddtAPI = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/serve/record/add',
    data: params
  })

// 企业详情-基本数据
export const basicDataAPI = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/enterprise/basicData',
    data: params
  })

// 企业详情-服务套餐记录
export const recordCompanyAPI = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/serve/record/company',
    data: params
  })

// 待关联子公司列表
export const affiliatedEnterprisePageAPI = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/enterprise/affiliatedEnterprisePage',
    data: params
  })

// 待关联子公司确认操作
export const affiliatedAPI = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/enterprise/affiliated',
    data: params
  })

// 企业详情–已关联企业列表
export const subordinateCompanyListAPI = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/enterprise/subordinateCompanyList',
    data: params
  })

// 企业详情–取消关联
export const disassociateAPI = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/enterprise/disassociate',
    data: params
  })

// 企业详情-缴费记录-详情
export const paymentDetailAPI = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterprise/payment/detail',
    data: params
  })
// 企业详情-缴费记录-编辑
export const paymentEditAPI = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterprise/payment/edit',
    data: params
  })
// 企业详情-调整试用时间
export const editTrialTimeAPI = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/serve/record/editTrialTime',
    data: params
  })
// 企业详情-增减车辆数
export const editCarNumAPI = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/serve/record/editCarNum',
    data: params
  })
// 企业违章转移获取信息
export const getSetting = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterpriseViolationSetting/getSetting',
    data: params
  })
// 企业违章转移保存
export const updateSetting = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterpriseViolationSetting/updateSetting',
    data: params
  })

