
import { defineComponent, reactive, ref, toRefs, onMounted } from 'vue'
import { digitUppercase } from '@/utils/index'
import { detailApi } from './api'
import { feeType, state, payChannel, getTableConfigDetail } from './config'
import CTable from '@/components/c-table'

export default defineComponent({
  props: {
    visible: Boolean,
    rowData: Object
  },
  components: { CTable },
  setup(props, { emit }) {
    const data = reactive({
      dialogVisible: props.visible,
      form: ref<any>({}),
      fillDeductionRecordList: ref<any>({}),
      formRef: null,
      tableConfig: getTableConfigDetail()
    })

    // 枚举
    const Enum = {
      feeType,
      state,
      payChannel
    }

    const handleClose = () => {
      emit('update:visible', false)
    }

    onMounted(() => {
      detailApi({ id: props.rowData.id }).then(res => {
        data.form = res.data
        data.fillDeductionRecordList = res.data.fillDeductionRecordList || []
        data.tableConfig.data = res.data.paymentDetailList || []
      })
    })

    return {
      ...toRefs(data),
      ...Enum,
      handleClose,
      digitUppercase
    }
  }
})
